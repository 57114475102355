<template>
  <v-container>
    <v-row class="py-3" justify="space-between">
      <v-col cols="auto">
        <v-btn
          class="font-weight-black"
          text
          color="primary"
          style="font-size: 1.375rem"
          :to="{ name: 'Projects' }"
          exact
        >
          <v-icon>mdi-undo-variant</v-icon>
          <span class="ml-4">{{ project.name }}</span>
        </v-btn>
      </v-col>

      <v-spacer />

      <!-- <v-col v-show="dashboards.length" cols="auto">
        <DashboardPermissionEditor>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-add-dashboard"
              color="primary"
              v-on="on"
              v-bind="attrs"
              :disabled="disableActionsBtn"
              >權限設定</v-btn
            >
          </template>
        </DashboardPermissionEditor>
      </v-col> -->
      <v-col v-if="isProjectOwner || roleGrafana.create" cols="auto">
        <DashboardAdder
          v-model="dialogDashboard"
          @successAdded="initialize(true)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-add-dashboard"
              color="primary"
              v-bind="attrs"
              rounded
              :disabled="disableActionsBtn"
              v-on="on"
            >
              {{ $t('page.dashboards.add_dashboard_title') }}
            </v-btn>
          </template>
        </DashboardAdder>
      </v-col>
    </v-row>
    <IssueCard
      v-if="error"
      class="mt-13"
      :message="$t('api_errors.data_error_refresh_page')"
      error
    />
    <v-card v-else outlined>
      <v-data-table
        class="dashboard-table"
        :headers="tableHeaders"
        :items="dashboards"
        :items-per-page="-1"
        :search="search"
        :item-class="() => 'dashboard-tr'"
        :loading="isLoading"
        :no-data-text="$t('page.dashboards.no_data_and_create_dashboard')"
        :loading-text="`${$t('loading')}...`"
        fixed-header
        border
        hide-default-footer
        style="width: 100%"
      >
        <!-- slot: top -->
        <!-- <template v-slot:top>
          <v-row justify="end" no-gutters>
            <v-col class="mt-4 mr-9" cols="auto">
              <v-text-field
                v-model="search"
                outlined
                hide-details
                dense
                prepend-inner-icon="mdi-magnify"
                placeholder="搜尋..."
              ></v-text-field>
            </v-col>
          </v-row>
        </template> -->

        <!-- slot: item.title -->
        <template v-slot:[`item.title`]="{ item: dashboard }">
          <span v-if="disableActionsBtn">{{ dashboard.title }}</span>
          <router-link
            v-else
            :title="dashboard.title"
            :to="toDashboard(dashboard)"
            :disabled="disableActionsBtn"
          >
            {{ dashboard.title }}
          </router-link>
        </template>

        <!-- slot: item.actions -->
        <template v-slot:[`item.actions`]="{ item: dashboard }">
          <v-btn
            v-if="isProjectOwner || roleDashboard(dashboard).update"
            icon
            :disabled="disableActionsBtn"
            @click="editDashboard(dashboard)"
          >
            <v-icon small color="primary">
              mdi-pencil
            </v-icon>
          </v-btn>
          <!-- <v-btn icon :disabled="disableActionsBtn">
            <v-icon small color="primary">mdi-content-copy</v-icon>
          </v-btn> -->
          <v-btn
            v-if="isProjectOwner || roleDashboard(dashboard).delete"
            icon
            :disabled="disableActionsBtn"
          >
            <v-icon small color="primary" @click="deleteDashboard(dashboard)">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <!-- slot: item.dashboard -->
        <template v-slot:[`item.comment`]="{ item: dashboard }">
          <span :title="dashboard.comment">{{ dashboard.comment }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import DashboardAdder from '@/components/DashboardAdder/DashboardAdder'
// import DashboardPermissionEditor from '@/components/DashboardPermissionEditor/DashboardPermissionEditor'
import { mapState, mapGetters } from 'vuex'

const tableHeaders = (vm) => [
  {
    text: vm.$t('dashboard_name'),
    value: 'title',
    width: '25%'
  },
  {
    text: vm.$t('dashboard_panel_count'),
    value: 'panelCount',
    width: '15%'
  },
  {
    text: vm.$t('action'),
    value: 'actions',
    width: '15%'
  },
  {
    text: vm.$t('description'),
    value: 'description',
    width: '45%'
  }
]

export default {
  name: 'Dashboards',

  components: {
    IssueCard,
    DashboardAdder
    // DashboardPermissionEditor
  },

  data: (vm) => ({
    dialogDashboard: false,
    search: '',
    error: false,
    isInitializing: false,
    isDeleting: false
  }),

  computed: {
    ...mapState({
      dashboards: (state) => state?.dashboards?.dashboards,
      isLoadingDashboards: (state) => state?.dashboards?.isLoadingDashboards
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),
    tableHeaders,

    isLoading() {
      return this.isInitializing || this.isLoadingDashboards
    },
    project() {
      return this.getProjectByRoute(this.$route) || {} // project || undefined
    },
    isProjectOwner() {
      return this.project.isOwner
    },

    roleGrafana() {
      return (
        this.getRoleByResourceId(this.project, this.project.grafanaId) || {}
      )
    },

    disableActionsBtn() {
      return this.isDeleting || this.isLoading
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize(forceUpdate = false) {
      this.isInitializing = true
      this.$store.dispatch('dashboards/init')
      try {
        if (!Array.isArray(this.project.permissions) || forceUpdate) {
          await this.fetchProject()
        }

        await this.fetchDashboards()
      } catch (error) {
        this.isInitializing = false

        this.$store.dispatch('snackbar/showError', {
          content: error
        })
      }

      this.isInitializing = false
    },
    fetchProject() {
      return this.$store.dispatch('projects/fetchProject', {
        projectId: this.$route.params.projectId
      })
    },
    fetchDashboards() {
      // if (this.isLoadingDashboards === true) return

      this.error = false
      return this.$store
        .dispatch('dashboards/fetchDashboards', {
          project: this.project
        })
        .catch(() => {
          this.error = true
        })
    },
    roleDashboard(dashboard) {
      return this.getRoleByResourceId(this.project, dashboard.resourceId) || {}
    },
    toDashboard(dashboard) {
      if (!this.project || !dashboard) return

      return {
        name: 'Dashboard',
        params: {
          projectId: this.project.id,
          dashboardId: dashboard.resourceId
        },
        query: this.$route.query
      }
    },
    editDashboard(dashboard) {
      this.$store
        .dispatch('dashboards/form/edit', {
          dashboard
        })
        .then(() => {
          this.dialogDashboard = true
        })
    },
    deleteDashboard(dashboard) {
      const confirm = window.confirm(
        this.$t('confirm_delete_item', {
          name: dashboard?.title
        })
      )

      if (!confirm || !dashboard) return

      this.isDeleting = true
      this.$store
        .dispatch('dashboards/deleteDashboard', {
          projectId: this.$route.params.projectId,
          dashboard
        })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_deleted')
          })
          this.fetchDashboards()
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default.btn-add-dashboard {
  padding: 20px 2.25rem;
  font-size: 1.25rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.dashboard-table {
  ::v-deep .v-data-table__wrapper {
    max-height: calc(100vh - 208px);
  }

  ::v-deep .v-data-table-header {
    tr th {
      font-size: 1.25rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .dashboard-tr {
    td {
      overflow: hidden;
      max-width: 120px;
      font-size: 1.125rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
