<template>
  <v-sheet color="grey lighten-3 d-flex flex-column" height="100%">
    <v-row
      ref="title"
      class="px-8 py-3 bg-ease flex-grow-0"
      no-gutters
    >
      <v-col class="step-title white--text">
        {{ $t('page.dashboards.bind_file_title') }}
      </v-col>
    </v-row>
    <FileManagerDashboard ref="content" />
  </v-sheet>
</template>

<script>
import FileManagerDashboard from '@/components/FileManager/FileManagerDashboard'

export default {
  name: 'DashboardAdderStep2',

  components: {
    FileManagerDashboard
  },

  data: () => ({
    timerDetectHeight: null
  }),

  mounted() {
    this.setContentMaxHeight()
  },

  beforeDestroy() {
    clearTimeout(this.timerDetectHeight)
  },

  methods: {
    setContentMaxHeight() {
      const title = this.$refs.title
      const content = this.$refs.content?.$el

      if (title && content) {
        const titleHeight = title?.getBoundingClientRect?.()?.height

        if (titleHeight && !Number.isNaN(titleHeight)) {
          content.style.height = `calc(100% - ${titleHeight}px)`
        } else {
          content.style.height = '100%'
        }
      }

      clearTimeout(this.timerDetectHeight)
      this.timerDetectHeight = setTimeout(this.setContentMaxHeight, 260)
    }
  }
}
</script>

<style lang="scss" scoped></style>
